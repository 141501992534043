import React, { useState, useEffect } from "react";
import { Modal, Row, Col, Table, Button, Form } from "react-bootstrap";

export default (props) => {
  const handleAddRecord = () => {
    if (nameList.length === 0 && deepEqual(initialValues, currentValues)) {
      return;
    }

    props.handlePassengerAdd({id: props?.projectId, vehicleId: props?.route?._id, passengerList: [...nameList, ...props?.nameList?.map(el => ({...el, isPassive: currentValues[el?._id]}))]});

    setNewName("");
    setNameList([]);
    
    setInitialValues(currentValues);
    // props.onClose();
  };

  const [newName, setNewName] = useState("");
  const [nameList, setNameList] = useState([]);

  const [initialValues, setInitialValues] = useState({});
  const [currentValues, setCurrentValues] = useState({});

  useEffect(() => {
    var obj = {};
    props?.nameList?.forEach(el => {
        obj[el?._id] = el?.isPassive ?? false;
    });

    setInitialValues(obj);
    setCurrentValues(obj);
}, [props?.show]);

  const handleSwitchChange = (e) => {
    // Handle the switch change event here
    setCurrentValues({...currentValues, [e?._id]: !currentValues[e?._id]});
    console.log(e, currentValues, initialValues);
  };

  const handleClose = () => {
    setNameList([]);setNewName('');setCurrentValues({});setInitialValues({});props.onClose();
}

  return (
    <Modal show={props.show} onHide={handleClose} centered>
      <Modal.Header closeButton>
        <Modal.Title>
          {props?.route?.name} {`(${props?.route?.plate})`} yolcu listesi
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Row>
          <h4>Yolcu listesi</h4>
        </Row>
        <Row>
          <Col>
            <Form.Group>
              <Row>
                <Col>
                  <Form.Control
                    type="text"
                    value={newName}
                    onChange={(e) => setNewName(e.target.value)}
                  />
                </Col>
                <Col>
                  <Button
                    variant="primary"
                    onClick={(e) => {setNameList([{ fullName: newName }, ...nameList]);setNewName("")}}
                  >
                    Yolcu ekle
                  </Button>
                </Col>
              </Row>
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col>
            <div
              style={{ height: "30vw", overflowY: "scroll", marginTop: 10 }}
            >
              <Table striped bordered hover>
                <colgroup>
                    <col style={{ width: "10%" }} />
                    <col style={{ width: "90%" }} />
                    <col style={{ width: "20%" }} />
                </colgroup>
                <thead style={{fontSize: '0.6vw'}}>
                  <tr>
                    <th>#</th>
                    <th>Ad soyad</th>
                    <th>Aktif</th>
                  </tr>
                </thead>
                <tbody className="yolcu">
                    <>
                    
                    {nameList?.map((el, index) => (
                    <tr key={`new-${index}`}>
                        <td className="yolcu" style={{ backgroundColor: '#c8f7d5' }}>{props.route?.passengerList?.length + (nameList?.length - index)}</td>
                        <td className="yolcu" style={{ backgroundColor: '#c8f7d5' }}>
                            {el.fullName}
                        </td>
                        <td>
                            <Button size='sm' onClick={(e) => setNameList([...nameList.filter(el2 => el2?.fullName != el?.fullName)])} variant="secondary">Sil</Button>
                        </td>
                    </tr>
                    ))}
                  {props.nameList?.map((el, index) => (
                    <tr key={index} style={{fontSize: '0.6vw !important'}}>
                      <td>{props.nameList?.length - index}</td>
                      <td>{el?.fullName}</td>
                      <td style={{ backgroundColor: initialValues[el?._id] != currentValues[el?._id] ? '#c8f7d5' : 'transparent' }}>
                        <Form.Check 
                            type="switch"
                            id={`custom-switch-${index}`}
                            checked={!currentValues[el?._id]}
                            onChange={(e) => handleSwitchChange(el)}
                        />
                    </td>
                    </tr>
                  ))}
                  {props.nameList?.length === 0 && nameList?.length === 0 && <tr><td colSpan={3}>Bu güzergaha kayıtlı hiçbir yolcu yok. Bir yolcu ekleyip, listeyi kaydedin.</td></tr>}
                  </>
                </tbody>
              </Table>
            </div>
          </Col>
        </Row>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Vazgeç
        </Button>
        <Button disabled={nameList?.length === 0 && deepEqual(initialValues, currentValues)} variant="primary" onClick={handleAddRecord}>
          Listeyi kaydet
        </Button>
      </Modal.Footer>
    </Modal>
  );
};


const deepEqual = (obj1, obj2) => {
    if (obj1 === obj2) return true;

    if (typeof obj1 !== 'object' || obj1 === null || typeof obj2 !== 'object' || obj2 === null) {
        return false;
    }

    const keys1 = Object.keys(obj1);
    const keys2 = Object.keys(obj2);

    if (keys1.length !== keys2.length) return false;

    for (let key of keys1) {
        if (!keys2.includes(key) || !deepEqual(obj1[key], obj2[key])) {
            return false;
        }
    }

    return true;
};
