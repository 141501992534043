import { useState, useEffect } from "react";

export default (props) => {
    const [time, setTime] = useState('');
    useEffect(() => {
        const updateClock = () => {
          const now = new Date().toLocaleString("en-US", { timeZone: "Europe/Istanbul" });
          const formattedTime = new Intl.DateTimeFormat("tr-TR", {
            timeStyle: "medium"
          }).format(new Date(now));
    
          setTime(formattedTime);
        };
    
        // Update the clock every second
        const intervalId = setInterval(updateClock, 1000);
    
        // Initialize clock
        updateClock();
    
        // Clear interval on component unmount
        return () => clearInterval(intervalId);
    }, []);

    return (
        <div style={{display: 'flex', alignItems: 'center', marginRight: '40px'}}>
            <div style={{
                fontFamily: 'monospace',
                fontSize: '16px',
                width: '80px',
                color: '#fff',
                textAlign: 'right',
                marginRight: '5px'
            }}>
                {time}
            </div>
            <img src={`${process.env.PUBLIC_URL}/tr-flag.webp`} style={{width: 20, paddingTop: 1}} />
        </div>
    )
}