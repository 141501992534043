import { Button, Card, Col, Container, Modal, Row, Table } from "react-bootstrap";
import {
  faAddressCard,
  faBus,
  faCertificate,
  faFile,
  faFileCircleCheck,
  faHeartCircleCheck,
  faIdBadge,
  faIdCard,
  faPersonCircleCheck,
  faVanShuttle,
} from "@fortawesome/free-solid-svg-icons";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { MainContext } from "../App";
import React from "react";
import apiService from "../api";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";

export const CustomerDriverDetail = (props) => {
  const typeMapping = {
    ehliyet: "Ehliyet",
    psikoTeknik: "Psikoteknik belgesi",
    sabikaKaydi: "Sabıka kaydı",
    saglikRaporu: "Sağlık raporu",
    srcTwo: "SRC2 belgesi",
    idCard: "Kimlik",
    isgCertificate: "ISG sertifikası",
    publicTransportationPermit: "Toplu taşıma kullanım belgesi",
  };

  const iconMapping = {
    ehliyet: faIdCard,
    psikoTeknik: faPersonCircleCheck,
    sabikaKaydi: faFileCircleCheck,
    saglikRaporu: faHeartCircleCheck,
    srcTwo: faVanShuttle,
    idCard: faIdBadge,
    isgCertificate: faCertificate,
    publicTransportationPermit: faBus,
  };

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const details = JSON.parse(queryParams.get("details"));
  const anyDriverDoc = Object.keys(details?.files ?? {}).some((key) => key in typeMapping);
  const { accessToken, darkMode, setSasToken, sasToken, setProgress } = React.useContext(MainContext);
  useEffect(() => {
    if (!accessToken)
      return;
      
    apiService.getWithFirstResolve('acquireSasTokenForDocs', accessToken, setProgress)
    .then(data => {
      setSasToken(data.headers.get('Sas-Token'));
      return data.json();
    })
    .then(data => (data));
  }, [])
  
  const generateDriverDocs = () => {
    return Object.keys(details?.files ?? {}).map((key) => {
      if (key in typeMapping) {
        // return <Card.Text key={key}>{typeMapping[key]}: {details[key]}</Card.Text>
        return (
          <Col
            style={{ marginTop: 10 }}
            xs={4}
            onClick={(e) =>
              window.open(
                details.files[key] + sasToken,
                typeMapping[key],
                "width:550px;height:550px"
              )
            }
          >
            <Card style={{ cursor: "pointer", padding: 3 }}>
              <Card.Body>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    height: '150px',
                    alignItems: "center",
                    justifyContent: "center",
                    textAlign: "center",
                  }}
                >
                  <div>
                    <FontAwesomeIcon
                      color={darkMode ? "white" : "black"}
                      size="2x"
                      icon={iconMapping[key]}
                    ></FontAwesomeIcon>
                  </div>
                  <span style={{ fontWeight: 500, fontSize: "18px" }}>
                    {typeMapping[key]}
                  </span>
                </div>
              </Card.Body>
            </Card>
          </Col>
        );
      }
      return null;
    });
  };

  return (
    <Container>
      <Card style={{ marginTop: 10, width: "100%" }}>
        <Card.Body>
          <Card.Title style={{ textAlign: "center" }}>
            Sürücü evrakları - {details.driverName ?? "(-)"}
          </Card.Title>
          {anyDriverDoc ? (
            <Row>{generateDriverDocs()}</Row>
          ) : (
            <Card.Text>
              <u>Sürücüye ait evrak bulunamadı.</u>
            </Card.Text>
          )}
        </Card.Body>
      </Card>
    </Container>
  );
};
