import * as XLSX from "xlsx-js-style";

import { Button, Card, Col, Form, Modal, Row, Table } from "react-bootstrap";
// VehicleModal.js
import React, { useContext, useEffect, useState, useCallback, useRef } from "react";
import {
  faCircleCheck,
  faCircleXmark,
  faCopy,
  faEdit,
} from "@fortawesome/free-solid-svg-icons";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { MainContext } from "../App"
import PassengerListModal from "./PassengerListModal";

export const RouteModal = ({
  projectName,
  show,
  handleClose,
  vehicles,
  showDocModal,
  handlePassengerListChange,
  projectId,
  onVehicleClick
}) => {
  const { signedInUserEmail, darkMode } = useContext(MainContext);
  const [searchTerm, setSearchTerm] = useState("");
  const [fontSize, setFontSize] = useState(14); // Default font size
  const [autoFontSize, setAutoFontSize] = useState(14);
  const [useAutoFontSize, setUseAutoFontSize] = useState(true);
  const tableRef = useRef(null);
  const [lastClickedIcon, setLastClickedIcon] = useState(null);

  const exportToExcel = () => {
    if (vehicles.length === 0) return;

    const newWs = XLSX.utils.aoa_to_sheet([]);
    const title = `${projectName} Güzergah Listesi`;
    XLSX.utils.sheet_add_aoa(newWs, [[title]], { origin: "A1" });
    XLSX.utils.sheet_add_aoa(newWs, [[""]], { origin: "A2" });
    newWs["A2"].s = {
      fill: {
        fgColor: { rgb: "ed545c" }, // Yellow background
      },
    };

    const links1 = [];
    const links2 = [];
    const links3 = [];
    XLSX.utils.sheet_add_json(
      newWs,
      vehicles?.map((vehicle) => {
        links1.push(
          `https://${
            window.location.host
          }/driverDetail?details=${encodeURIComponent(JSON.stringify({...vehicle, passengerList: undefined}))}`
        );
        links2.push(
          `https://${
            window.location.host
          }/vehicleDetailPart?details=${encodeURIComponent(
            JSON.stringify({...vehicle, passengerList: undefined})
          )}`
        );
        links3.push(
          `https://${window.location.host}/vehicleDetail/${
            vehicle.vehicleId
          }?details=${encodeURIComponent(JSON.stringify({...vehicle, passengerList: undefined}))}`
        );
        return {
          Güzergah: vehicle.name ?? '-',
          Sürücü: vehicle.driverName ?? '-',
          "Tel no.": vehicle.driverPhone ?? '-',
          Plaka: vehicle.plate ?? '-',
          "GPS Link": `Tıkla ${vehicle?.lastLocation ? "✔" : "✖"}`,
        };
      }),
      { origin: "A3" }
    );

    // Merge cells for the title
    const merge = [{ s: { r: 0, c: 0 }, e: { r: 0, c: 4 } }];
    if (!newWs["!merges"]) newWs["!merges"] = [];
    newWs["!merges"] = newWs["!merges"].concat(merge);

    const merge3 = [{ s: { r: 1, c: 0 }, e: { r: 1, c: 4 } }];
    if (!newWs["!merges"]) newWs["!merges"] = [];
    newWs["!merges"] = newWs["!merges"].concat(merge3);

    // Apply styling to the title
    newWs["A1"].s = {
      font: {
        name: "Arial",
        sz: 20, // Increase the font size
        bold: true,
        color: { rgb: "FFFFFF" }, // White text
      },
      fill: {
        fgColor: { rgb: "14345c" }, // Black background
      },
      alignment: {
        horizontal: "center",
        vertical: "center",
      },
    };

    const wStyle = {
      font: {
        name: "Arial",
        sz: 10,
        color: { rgb: "ffffff" },
      },
      fill: {
        fgColor: { rgb: "43648c" }, // Yellow background
      },
      alignment: {
        horizontal: "center",
        vertical: "center",
      },
      border: {
        top: {
          style: "medium",
          color: { rgb: "000000" },
        },
        bottom: {
          style: "medium",
          color: { rgb: "000000" },
        },
        left: {
          style: "medium",
          color: { rgb: "000000" },
        },
        right: {
          style: "medium",
          color: { rgb: "000000" },
        },
      },
    };

    const wStyleH = {
      font: {
        name: "Arial",
        sz: 10,
        color: { rgb: "ffffff" },
      },
      fill: {
        fgColor: { rgb: "14345c" }, // Yellow background
      },
      alignment: {
        horizontal: "center",
        vertical: "center",
      },
      border: {
        top: {
          style: "medium",
          color: { rgb: "000000" },
        },
        bottom: {
          style: "medium",
          color: { rgb: "000000" },
        },
        left: {
          style: "medium",
          color: { rgb: "000000" },
        },
        right: {
          style: "medium",
          color: { rgb: "000000" },
        },
      },
    };

    const paint = ["A", "B", "C", "D", "E"];
    paint.forEach((el) => {
      if (!newWs[`${el}3`]) newWs[`${el}3`] = {};
      newWs[`${el}3`].s = wStyleH;

      for (let i = 4; i < vehicles.length + 4; i++) {
        if (!newWs[`${el}${i}`]) newWs[`${el}${i}`] = {};
        newWs[`${el}${i}`].s = wStyle;

        if (el === "B") {
          if (!newWs[`${el}${i}`]) newWs[`${el}${i}`] = {};
          newWs[`${el}${i}`].l = {
            Target: links1[i - 4],
            Tooltip: "Sürücü evrakları",
          };
        }

        if (el === "D") {
          if (!newWs[`${el}${i}`]) newWs[`${el}${i}`] = {};
          newWs[`${el}${i}`].l = {
            Target: links2[i - 4],
            Tooltip: "Araç evrakları",
          };
        }

        if (el === "E") {
          if (!newWs[`${el}${i}`]) newWs[`${el}${i}`] = {};
          newWs[`${el}${i}`].l = { Target: links3[i - 4], Tooltip: `GPS` };
        }

        if (el === "A" || el === "B") {
          newWs[`${el}${i}`].s = {
            ...wStyle,
            alignment: { horizontal: "left", vertical: "center" },
          };
        }
      }
    });

    var wscols = [
      { wch: 50 },
      { wch: 35 },
      { wch: 15 },
      { wch: 15 },
      { wch: 20 },
    ];

    newWs["!cols"] = wscols;

    const createdByRow = vehicles.length + 4;
    const createdBy = `${
      signedInUserEmail ?? "-"
    } tarafından ${new Date().toLocaleDateString()} tarihinde oluşturuldu.`;

    XLSX.utils.sheet_add_aoa(newWs, [[createdBy]], {
      origin: `A${createdByRow}`,
    });

    newWs[`A${createdByRow}`].s = {
      font: {
        name: "Arial",
        sz: 11, // Increase the font size
        bold: true,
        color: { rgb: "FFFFFF" }, // White text
      },
      fill: {
        fgColor: { rgb: "000000" }, // Black background
      },
      alignment: {
        horizontal: "center",
        vertical: "center",
      },
    };

    const merge2 = [
      { s: { r: createdByRow - 1, c: 0 }, e: { r: createdByRow - 1, c: 4 } }, // Merging cells A11, B11, and C11
    ];
    if (!newWs["!merges"]) newWs["!merges"] = [];
    newWs["!merges"] = newWs["!merges"].concat(merge2);

    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, newWs, "Ana sayfa");
    XLSX.writeFile(wb, `${projectName}-güzergah-listesi.xlsx`);
  };

  const [showPassengerListModal, setShowPassengerListModal] = useState(false);
  const [currentNameList, setCurrentNameList] = useState(null);
  const [currentRoute, setCurrentRoute] = useState(null);

  useEffect(() => {
    setCurrentNameList(vehicles?.find(el => el?._id == currentRoute?._id)?.passengerList);
  }, [vehicles]);

  const handleShowPassengerListModal = (nameList, route) => {
    setCurrentNameList(nameList);
    setCurrentRoute(route);
    setShowPassengerListModal(true);
  };

  // Function to change font size
  const changeFontSize = useCallback((delta) => {
    setFontSize((prevSize) => {
      const newSize = prevSize + delta;
      return Math.max(10, Math.min(20, newSize)); // Limit font size between 10px and 20px
    });
    setUseAutoFontSize(false);
  }, []);

  useEffect(() => {
    const handleResize = () => {
      if (tableRef.current && useAutoFontSize) {
        const tableWidth = tableRef.current.offsetWidth;
        const newFontSize = Math.max(10, Math.min(14, tableWidth / 50));
        setAutoFontSize(newFontSize);
      }
    };

    window.addEventListener('resize', handleResize);
    handleResize(); // Initial calculation

    return () => window.removeEventListener('resize', handleResize);
  }, [useAutoFontSize]);

  const effectiveFontSize = useAutoFontSize ? autoFontSize : fontSize;

  return (
    <>
      <Modal show={show} onHide={handleClose} size="xl" fullscreen>
        <Modal.Header closeButton>
          <Modal.Title>{projectName} Güzergah Listesi</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row className="mb-3">
            <Col xs={12} sm={6} className="mb-2 mb-sm-0">
              <Form.Control
                type="text"
                placeholder="Güzergah ara..."
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
              />
            </Col>
            <Col xs={12} sm={6} className="d-flex justify-content-sm-end">
              <Button
                variant="primary"
                onClick={exportToExcel}
              >
                Excel indir
              </Button>
            </Col>
          </Row>
          <div style={{ overflowX: "auto", height: "calc(100vh - 200px)" }}>
            <Table
              ref={tableRef}
              striped
              bordered
              hover
              style={{
                tableLayout: "auto",
                marginTop: 10,
                marginBottom: 10,
                fontSize: `${effectiveFontSize}px`
              }}
            >
              <thead>
                <tr>
                  <th>Güzergah</th>
                  <th>Sürücü</th>
                  <th>Tel no.</th>
                  <th>Plaka</th>
                  <th>Yolcu sayısı</th>
                  <th>Koltuk sayısı</th>
                  <th>GPS</th>
                </tr>
              </thead>
              <tbody>
                {vehicles
                  ?.filter((el) =>
                    el?.name?.toLocaleLowerCase('tr').includes(searchTerm.toLocaleLowerCase('tr'))
                  )
                  ?.map((route, idx) => (
                    <tr key={route.vehicleId + idx}>
                      <td>{route.name}</td>
                      <td>
                        {route.driverName ? (
                          <Button
                            variant="link"
                            style={{ padding: 0, fontSize: 'inherit' }}
                            onClick={() => {
                              window.open(
                                `http://${window.location.host}/driverDetail?details=${JSON.stringify({...route, passengerList: undefined})}`,
                                `_blank`,
                                "width=900,height=900,popup=true"
                              );
                            }}
                          >
                            {route.driverName}
                          </Button>
                        ) : (
                          <span className="text-muted">-</span>
                        )}
                      </td>
                      <td>
                        {route.driverPhone ? (
                          <div style={{ display: "flex", alignItems: "center" }}>
                            <a href={`tel:+90${route.driverPhone}`}>
                              {route.driverPhone}
                            </a>
                            <FontAwesomeIcon
                              className={`copy-icon ${lastClickedIcon === `phone-${route.vehicleId}` ? 'animate-copy' : ''}`}
                              icon={faCopy}
                              color={darkMode ? "white" : "black"}
                              style={{ marginLeft: "10px", cursor: "pointer" }}
                              onClick={() => {
                                navigator.clipboard.writeText(`+90${route.driverPhone}`);
                                setLastClickedIcon(`phone-${route.vehicleId}`);
                                setTimeout(() => setLastClickedIcon(null), 500); // Reset after animation
                              }}
                            />
                          </div>
                        ) : (
                          <span className="text-muted">
                            {"-"}
                          </span>
                        )}
                      </td>
                      <td>
                        {route.plate ? (
                          <Button
                            variant="link"
                            style={{ padding: 0, fontSize: 'inherit' }}
                            onClick={() => {
                              let doc = window.open(
                                `http://${
                                  window.location.host
                                }/vehicleDetailPart?details=${JSON.stringify({...route, passengerList: undefined})}`,
                                `${route.plate}`,
                                "width=900,height=750"
                              );
                              doc.title = route.plate;
                            }}
                          >
                            {route.plate}
                          </Button>
                        ) : (
                          <span className="text-muted">
                            {"-"}
                          </span>
                        )}
                      </td>
                      <td>
                        <span>
                          {route.passengerList?.length}
                          <FontAwesomeIcon
                            onClick={() => handleShowPassengerListModal(route.passengerList, route)}
                            icon={faEdit}
                            color={darkMode ? "white" : "black"}
                            style={{ marginLeft: 5, cursor: "pointer" }}
                          />
                        </span>
                      </td>
                      <td>
                        {route.capacity ? (
                          <span>{route.capacity}</span>
                        ) : (
                          <span className="text-muted">
                            {"-"}
                          </span>
                        )}
                      </td>
                      <td>
                        <Button
                          variant="link"
                          onClick={() => onVehicleClick(route)}
                        >
                          <div
                            title="GPS"
                            style={{
                              whiteSpace: "nowrap",
                              overflow: "hidden",
                              textOverflow: "ellipsis",
                            }}
                          >
                            <span className="disappear-on-small">GPS</span>
                            {route?.lastLocation ? (
                              <FontAwesomeIcon
                                icon={faCircleCheck}
                                color="green"
                                style={{ marginLeft: 5 }}
                              />
                            ) : (
                              <FontAwesomeIcon
                                icon={faCircleXmark}
                                color="red"
                                style={{ marginLeft: 5 }}
                              />
                            )}
                          </div>
                        </Button>
                      </td>
                    </tr>
                  ))}
              </tbody>
            </Table>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <div style={{ display: 'flex', alignItems: 'center', marginRight: 'auto' }}>
            <Button
              variant="outline-secondary"
              size="sm"
              onClick={() => changeFontSize(-1)}
              style={{ marginRight: '5px' }}
            >
              A-
            </Button>
            <span style={{ margin: '0 5px', fontSize: '0.9rem' }}>{effectiveFontSize.toFixed(1)}px</span>
            <Button
              variant="outline-secondary"
              size="sm"
              onClick={() => changeFontSize(1)}
              style={{ marginRight: '15px' }}
            >
              A+
            </Button>
            <Form.Check 
            type="switch"
            id="auto-font-switch"
            label={useAutoFontSize ? "Otomatik" : "Manuel"}
            checked={useAutoFontSize}
            onChange={() => setUseAutoFontSize(!useAutoFontSize)}
            style={{ marginRight: '15px' }}
          />
          </div>
          <Button
            size="sm"
            onClick={(e) => {
              showDocModal(true);
            }}
          >
            Toplu evrak listesi
          </Button>
          <Button variant="secondary" onClick={handleClose}>
            Kapat
          </Button>
        </Modal.Footer>
      </Modal>
      <PassengerListModal
        onClose={() => setShowPassengerListModal(false)}
        show={showPassengerListModal}
        nameList={currentNameList}
        route={currentRoute}
        handlePassengerAdd={handlePassengerListChange}
        projectId={projectId}
      />
    </>
  );
};