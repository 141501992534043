// VehicleModal.js
import React, { useEffect, useContext, useState, useCallback, useRef } from 'react';
import { Modal, Table, Button, Row, Col, Card, Form } from 'react-bootstrap';
import { faCopy } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as XLSX from 'xlsx-js-style';
import { createPath } from 'react-router-dom';
import { MainContext } from '../App';
import styled from 'styled-components';

const ResponsiveTable = styled(Table)`
  @media (max-width: 768px) {
    font-size: 0.7rem;
  }
  table-layout: fixed;
  width: 100%;
`;

const TableHeader = styled.th`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 120px;
  width: 120px;
  font-size: 0.8rem;
  vertical-align: middle !important;
  padding: 0.5rem !important;
`;

const TableCell = styled.td`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 120px;
  width: 120px;
  vertical-align: middle !important;
  padding: 0.5rem !important;
`;

export const DocumentModal = ({ projectName, show, handleClose, vehicles }) => {
  const { signedInUserEmail, darkMode } = useContext(MainContext);
  const [searchTerm, setSearchTerm] = useState('');
  const [rows, setRows] = useState([]);
  const [exRows, setExcelRows] = useState([]);
  const [fontSize, setFontSize] = useState(14);
  const [autoFontSize, setAutoFontSize] = useState(14);
  const [useAutoFontSize, setUseAutoFontSize] = useState(true);
  const tableRef = useRef(null);

  // Function to change font size
  const changeFontSize = useCallback((delta) => {
    setFontSize((prevSize) => {
      const newSize = prevSize + delta;
      return Math.max(10, Math.min(20, newSize));
    });
    setUseAutoFontSize(false);
  }, []);

  useEffect(() => {
    const handleResize = () => {
      if (tableRef.current && useAutoFontSize) {
        const tableWidth = tableRef.current.offsetWidth;
        const newFontSize = Math.max(10, Math.min(14, tableWidth / 50));
        setAutoFontSize(newFontSize);
      }
    };

    window.addEventListener('resize', handleResize);
    handleResize(); // Initial calculation

    return () => window.removeEventListener('resize', handleResize);
  }, [useAutoFontSize]);

  const effectiveFontSize = useAutoFontSize ? autoFontSize : fontSize;

  const exportToExcel = () => {
    if (exRows.length === 0) return;

    const newWs = XLSX.utils.aoa_to_sheet([]);
    const title = `${projectName} Toplu Evrak Listesi`;
    XLSX.utils.sheet_add_aoa(newWs, [[title]], { origin: 'A1' });
    XLSX.utils.sheet_add_aoa(newWs, [[""]], { origin: 'A2' });
    newWs['A2'].s = {
        fill: {
            fgColor: { rgb: "ed545c" } // Yellow background
        },
    };
    
    XLSX.utils.sheet_add_json(newWs, exRows?.map(row => ({
      'Güzergah': row.name,
      'Plaka': row.plate,
      'Ehliyet': row.ehliyet,
      'Psikoteknik Belgesi': row.psikoTeknik,
      'Sabıka Kaydı': row.sabikaKaydi,
      'Sağlık Raporu': row.saglikRaporu,
      'SRC2 Belgesi': row.srcTwo,
      'Muayene Belgesi': row.muayene,
      'Ruhsat': row.ruhsat,
      'Koltuk Sigorta Belgesi': row.koltukSigortasi,
      'Trafik Sigorta Belgesi': row.trafikSigortasi,
      'Risk analizi': row.riskAnalysis,
      'ISG sertifikası': row.isgCertificate,
      'Kimlik': row.idCard,
      'Acil eylem kaçış planı': row.emergencyEvacuationPlan,
      'Toplu taşıma kullanım belgesi': row.publicTransportationPermit
    })), {origin: 'A3'});
    
    // Merge cells for the title
    const merge = [
      { s: { r: 0, c: 0 }, e: { r: 0, c: 15 } }
    ];
    if (!newWs['!merges']) newWs['!merges'] = [];
    newWs['!merges'] = newWs['!merges'].concat(merge);

    const merge3 = [
        { s: { r: 1, c: 0 }, e: { r: 1, c: 15 } }
    ];
    if (!newWs['!merges']) newWs['!merges'] = [];
    newWs['!merges'] = newWs['!merges'].concat(merge3);
    
    // Apply styling to the title
    newWs['A1'].s = {
        font: {
            name: 'Arial',
            sz: 20, // Increase the font size
            bold: true,
            color: { rgb: "FFFFFF" } // White text
        },
        fill: {
            fgColor: { rgb: "14345c" } // Black background
        },
        alignment: {
            horizontal: 'center',
            vertical: 'center'
        }
    };

    const wStyle = {
        font: {
            name: 'Arial',
            sz: 10,
            color: { rgb: "ffffff" }
        },
        fill: {
            fgColor: { rgb: "43648c" } // Yellow background
        },
        alignment: {
            horizontal: 'center',
            vertical: 'center'
        },
        border: {
            top: {
                style: 'medium',
                color: { rgb: "000000" }
            },
            bottom: {
                style: 'medium',
                color: { rgb: "000000" }
            },
            left: {
                style: 'medium',
                color: { rgb: "000000" }
            },
            right: {
                style: 'medium',
                color: { rgb: "000000" }
            }
        }
    };
    
    const wStyleH = {
      font: {
          name: 'Arial',
          sz: 10,
          color: { rgb: "ffffff" }
      },
      fill: {
          fgColor: { rgb: "14345c" } // Yellow background
      },
      alignment: {
          horizontal: 'center',
          vertical: 'center'
      },
      border: {
          top: {
              style: 'medium',
              color: { rgb: "000000" }
          },
          bottom: {
              style: 'medium',
              color: { rgb: "000000" }
          },
          left: {
              style: 'medium',
              color: { rgb: "000000" }
          },
          right: {
              style: 'medium',
              color: { rgb: "000000" }
          }
      }
    };

    const wEStyle = {
      font: {
          name: 'Arial',
          sz: 10,
          color: { rgb: "ffffff" }
      },
      fill: {
          fgColor: { rgb: "eb7f84" } // Yellow background
      },
      alignment: {
          horizontal: 'center',
          vertical: 'center'
      },
      border: {
          top: {
              style: 'medium',
              color: { rgb: "000000" }
          },
          bottom: {
              style: 'medium',
              color: { rgb: "000000" }
          },
          left: {
              style: 'medium',
              color: { rgb: "000000" }
          },
          right: {
              style: 'medium',
              color: { rgb: "000000" }
          }
      }
  };

    const paint = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P'];
    paint.forEach((el) => {
        if (!newWs[`${el}3`]) newWs[`${el}3`] = {}; 
        newWs[`${el}3`].s = wStyleH;
        
        for (let i = 4; i < vehicles.length + 4; i++) {
            if (!newWs[`${el}${i}`]) newWs[`${el}${i}`] = {}; 
            if (newWs[`${el}${i}`].v != 'Aktif' && el != 'A' && el != 'B') {
              newWs[`${el}${i}`].s = wEStyle;
            } else {
              newWs[`${el}${i}`].s = wStyle;
            }

            if (el === 'A' || el === 'B')
            {
                newWs[`${el}${i}`].s = {...wStyle, alignment: { horizontal: 'left', vertical: 'center' }};
            }
        }
    });

    var wscols = [
      {wch:35},
      {wch:20},
      {wch:12},
      {wch:12},
      {wch:12},
      {wch:12},
      {wch:12},
      {wch:12},
      {wch:12},
      {wch:12},
      {wch:12}
    ];

    newWs['!cols'] = wscols;

    const createdByRow = vehicles.length + 4;
    const createdBy = `${signedInUserEmail ?? '-'} tarafından ${new Date().toLocaleDateString()} tarihinde oluşturuldu.`;
    
    XLSX.utils.sheet_add_aoa(newWs, [[createdBy]], { origin: `A${createdByRow}` });

    newWs[`A${createdByRow}`].s = {
        font: {
            name: 'Arial',
            sz: 11, // Increase the font size
            bold: true,
            color: { rgb: "FFFFFF" } // White text
        },
        fill: {
            fgColor: { rgb: "000000" } // Black background
        },
        alignment: {
            horizontal: 'center',
            vertical: 'center'
        }
    };

    const merge2 = [
      { s: { r: createdByRow - 1, c: 0 }, e: { r: createdByRow - 1, c: 15 } } // Merging cells A11, B11, and C11
    ];
    if (!newWs['!merges']) newWs['!merges'] = [];
    newWs['!merges'] = newWs['!merges'].concat(merge2);

    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, newWs, 'Ana sayfa');
    XLSX.writeFile(wb, `${projectName}-toplu-evrak-listesi.xlsx`);
  };

  const fileKeys = [
    "ehliyet",
    "psikoTeknik",
    "sabikaKaydi",
    "saglikRaporu",
    "muayene",
    "ruhsat",
    "trafikSigortasi",
    "isgCertificate",
  ]

  const typeMapping = {
    "muayene": "Muayene belgesi",
    "ruhsat": "Ruhsat",
    "koltukSigortasi": "Koltuk sigorta belgesi",
    "trafikSigortasi": "Trafik sigorta belgesi",
    "ehliyet": "Ehliyet",
    "psikoTeknik": "Psikoteknik belgesi",
    "sabikaKaydi": "Sabıka kaydı",
    "saglikRaporu": "Sağlık raporu",
    "srcTwo": "SRC2 belgesi",
    "riskAnalysis": "Risk analizi",
    "isgCertificate": 'ISG sertifikası',
    "idCard": "Kimlik",
    "emergencyEvacuationPlan": "Acil eylem kaçış planı",
    "publicTransportationPermit": "Toplu taşıma kullanım belgesi"
  }

  const dateDifferenceInDays = (date1, date2) => {
    const oneDay = 24 * 60 * 60 * 1000; // milliseconds in one day
    const diffInTime = date1.getTime() - date2.getTime();
    const diffInDays = Math.round(diffInTime / oneDay);
    return diffInDays;
  };
  
  const generateDocs = () => {
    try {
      let rows = [];
      vehicles?.filter(el => `${el?.plate} / ${el?.name}`.toLocaleLowerCase('tr')?.includes(searchTerm)).forEach(el => {
          let cols = {
            plate: el.plate,
            name: el.name,
            id: `${el.plate} / ${el.name}`
          }
          fileKeys.forEach(key => {
            console.log(el)
              if (!el?.files[key]) {
                  cols[key] = "Eksik"
              } else {
                let prefixLen = "https://kentistturizmdocs.blob.core.windows.net/docs/".length;
                let fileExpiryDateSplit = el?.files?.[key]?.slice(prefixLen, -1)?.split('-')[0].split('.');
                if (fileExpiryDateSplit.length === 3)
                {
                    let year = fileExpiryDateSplit[2];
                    let month = fileExpiryDateSplit[1] - 1;
                    let day = fileExpiryDateSplit[0];
                    let date = new Date(year, month, day)
                    
                    let now = new Date()
                    let aMonthLater = new Date()
                    aMonthLater.setMonth(aMonthLater.getMonth() + 1)
                    
                    if (date < aMonthLater)
                    {
                        cols[key] = `${dateDifferenceInDays(date, now)} gün`
                        return;
                    }
                }
                cols[key] = "Aktif"
              }
          });
          rows.push(cols)
      })
      console.log(rows)
      setExcelRows(rows)
      setRows(rows)
    } catch (e) {
      console.log(e)
    }
  }

  useEffect(() => {
    generateDocs()
  }, [vehicles, searchTerm])
  
  return (
    <Modal show={show} onHide={(e) => handleClose()} size="xl">
      <Modal.Header closeButton>
        <Modal.Title>{projectName} Toplu Evrak Listesi</Modal.Title>
      </Modal.Header>
      <Modal.Body style={{height: '75vh', overflowY: 'auto'}}>
        <Row className="mb-3">
          <Col xs={12} sm={6} className="mb-2 mb-sm-0">
            <Form.Control
              type="text"
              placeholder="Plaka veya güzergah ara..."
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
            />
          </Col>
          <Col xs={12} sm={6} className="d-flex justify-content-sm-end">
            <Button variant="primary" onClick={() => exportToExcel()}>
              Excel indir
            </Button>
          </Col>
        </Row>
        <div style={{ overflowX: 'auto' }}>
          <ResponsiveTable 
            ref={tableRef}
            striped 
            bordered 
            hover 
            style={{
              marginTop: 10, 
              marginBottom: 10,
              fontSize: `${effectiveFontSize}px`
            }}
          >
            <thead>
              <tr>
                <TableHeader style={{width: '150px'}}>Güzergah</TableHeader>
                <TableHeader style={{width: '80px'}}>Plaka</TableHeader>
                {fileKeys.map(el => (
                  <TableHeader key={el} title={typeMapping[el]}>{typeMapping[el]}</TableHeader>
                ))}
              </tr>
            </thead>
            <tbody>
              {rows.map((row, index) => (
                <tr key={index}>
                  <TableCell style={{width: '150px'}}>{row.name}</TableCell>
                  <TableCell style={{width: '80px'}}>{row.plate}</TableCell>
                  {fileKeys.map(key => (
                    <TableCell 
                      key={key} 
                      style={{
                        backgroundColor: 
                          row[key] === "Aktif" ? "#d6ffeb" : 
                          row[key] === "Eksik" ? "#ffabc3" : "#ffebee"
                      }}
                    >
                      {row[key]}
                    </TableCell>
                  ))}
                </tr>
              ))}
            </tbody>
          </ResponsiveTable>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <div style={{ display: 'flex', alignItems: 'center', marginRight: 'auto' }}>
          <Button
            variant="outline-secondary"
            size="sm"
            onClick={() => changeFontSize(-1)}
            style={{ marginRight: '5px' }}
          >
            A-
          </Button>
          <span style={{ margin: '0 5px', fontSize: '0.9rem' }}>{effectiveFontSize.toFixed(1)}px</span>
          <Button
            variant="outline-secondary"
            size="sm"
            onClick={() => changeFontSize(1)}
            style={{ marginRight: '15px' }}
          >
            A+
          </Button>
          <Form.Check 
            type="switch"
            id="auto-font-switch"
            label={useAutoFontSize ? "Otomatik" : "Manuel"}
            checked={useAutoFontSize}
            onChange={() => setUseAutoFontSize(!useAutoFontSize)}
            style={{ marginRight: '15px' }}
          />
        </div>
        <Button variant="secondary" onClick={handleClose}>
          Kapat
        </Button>
      </Modal.Footer>
    </Modal>
  );
};