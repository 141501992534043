import { GetApiBaseUrl } from "./environment";

const baseAPI = GetApiBaseUrl() + '/api';

const apiService = {
  get(relativeUrl, accessToken, progressCallback, errorCallback) {
    progressCallback(true);
    return new Promise((resolve, reject) => {
      fetch(`${baseAPI}/${relativeUrl}`, {headers: {'Authorization': accessToken}})
        .then(response => response.json())
        .then(json => resolve(json))
        .catch(err => {
          console.log(err);
        })
        .finally(() => progressCallback(false));
    });
  },

  getWithFirstResolve(relativeUrl, accessToken, progressCallback, errorCallback) {
    progressCallback(true);
    return new Promise((resolve, reject) => {
      fetch(`${baseAPI}/${relativeUrl}`, {headers: {'Authorization': accessToken}})
        .then(response => resolve(response))
        .catch(err => {
          console.log(err);
        })
        .finally(() => progressCallback(false));
    });
  },

  getOne(relativeUrl, accessToken, progressCallback, errorCallback) {
    progressCallback(true);
    return new Promise((resolve, reject) => {
      fetch(`${baseAPI}/${relativeUrl}`, {headers: {'Authorization': accessToken}})
        .then(response => resolve(response))
        .catch(err => {
          console.log(err);
        })
        .finally(() => progressCallback(false));
    });
  },

  create(vehicle, relativeUrl, accessToken, progressCallback, errorCallback) {
    progressCallback(true);
    return new Promise((resolve, reject) => {
      fetch(`${baseAPI}/${relativeUrl}`, {
        method: 'PUT',
        body: JSON.stringify(vehicle),
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'Authorization': accessToken
        }
      })
        .then(result => result.json())
        .then(json => resolve(json))
        .catch(err => {
          console.log(err);
        })
        .finally(() => progressCallback(false));
    });
  },

  uploadFile(file, expiryDate, relativeUrl, accessToken, progressCallback, errorCallback, customer = false) {
    if (expiryDate === '' || expiryDate === undefined)
    {
      const date = new Date();
      const  currentDay = date.getDate();
      const  currentMonth = date.getMonth() + 1;
      const currentYear = date.getFullYear();
      expiryDate = `${currentDay}.${currentMonth}.${currentYear + 1}`
    }
    progressCallback(true);
    const formData = new FormData();
    formData.append('file', file);
    formData.append('expiryDate', expiryDate)

    return new Promise((resolve, reject) => {
      const url = customer ? `${baseAPI}/customer/uploadFile` : `${baseAPI}/${relativeUrl}`;
      fetch(url, {
        method: 'POST',
        body: formData,
        headers: {
          'Authorization': accessToken
        }
      })
        .then(result => result.json())
        .then(json => resolve(json))
        .catch(err => {
          console.log(err);
        })
        .finally(() => progressCallback(false));
    });
  },

  update(vehicle, relativeUrl, accessToken, progressCallback, errorCallback) {
    progressCallback(true);
    return new Promise((resolve, reject) => {
      fetch(`${baseAPI}/${relativeUrl}`, {
        method: 'POST',
        body: JSON.stringify(vehicle),
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'Authorization': accessToken
        }
      })
        .then(result => {
          if (result.status >= 300 && result.status <= 500)
          {
            errorCallback(result);
          }
          return result.json()
        })
        .then(res => {
          return resolve(res)
        })
        .catch(err => {
          console.log(err);
        })
        .finally(() => progressCallback(false));
    });
  },

  destroy(vehicle, relativeUrl, accessToken, progressCallback, errorCallback) {
    progressCallback(true);
    return new Promise((resolve, reject) => {
      fetch(`${baseAPI}/${relativeUrl}/${vehicle.id}`, { method: 'DELETE', headers: {'Authorization': accessToken} })
        .then(response => response.json())
        .then(json => resolve(json))
        .catch(err => {
          console.log(err);
        })
        .finally(() => progressCallback(false));
    });
  },

  uploadFileForComplaint(file, progressCallback, errorCallback) {
    progressCallback(true);
    const formData = new FormData();
    formData.append('file', file);

    return new Promise((resolve, reject) => {
      fetch(`${baseAPI}/customer/uploadFile`, {
        method: 'POST',
        body: formData,
      })
        .then(result => result.json())
        .then(json => resolve(json))
        .catch(err => {
          console.error(err);
          errorCallback(err);
        })
        .finally(() => progressCallback(false));
    });
  },

  sendComplaint(complaint, progressCallback, errorCallback) {
    progressCallback(true);
    return new Promise((resolve, reject) => {
      fetch(`${baseAPI}/customercomplaint`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(complaint),
      })
        .then(response => {
          if (!response.ok) {
            throw response;
          }
          return response.json();
        })
        .then(json => resolve(json))
        .catch(err => {
          console.error(err);
          errorCallback(err);
        })
        .finally(() => progressCallback(false));
    });
  }
};

export default apiService;
