import { Button, Col, Form, Card, Row, Container } from 'react-bootstrap';
import React, { useContext, useState } from 'react';
import ComplaintPage from './ComplaintPage';
import { CustomerDetailPage } from './CustomerDetailPage'
import { MainContext } from '../App';
import apiService from '../api';
import '../App.css';

export const LoginPageWithUserDetail = (props) => {
    const { accessToken, setAccessToken, setProgress, setErrorMessage, reactGa } = useContext(MainContext);
    const [formData, setFormData] = useState({
        email: '',
        password: '',
    });
    
    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        const { email, password } = formData;
        reactGa.event({
            category: "login",
            action: "login",
            label: email,
            nonInteraction: false, // optional, true/false
        });
        apiService.update({email, password}, 'login', null, setProgress, setErrorMessage)
          .then(data => {
              if (data.accessToken === undefined)
                  return;
            setAccessToken(data.accessToken);
            localStorage.setItem('kentist-musteri-accessToken', data.accessToken);
          });
    };

    if (window.location.pathname === '/sikayet') {
        return <ComplaintPage />;
    };
    
    if (accessToken) {
        return <div style={{padding: 10}}><CustomerDetailPage /></div>;
    } else {
        return (
          <div stlye={{padding: 10}}>
          <div className="login-container" style={{marginBottom: 125, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
      <Card style={{ borderRadius: '5px', width: '100%', boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)' }}>
      
        <Row noGutters>
          <Col md={6}>
            {/* This column is for the image */}
            <Card.Img variant="left" style={{objectFit: 'cover', maxHeight: '100%', width: '100%'}} src={`${process.env.PUBLIC_URL}/kentistxtulpar.png`} />      
            
          </Col>
          <Col md={6} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <Card.Body>
              <h2 className="text-center my-4">Müşteri girişi</h2>
              <Form onSubmit={handleSubmit}>
                <Form.Group controlId="email">
                  <Form.Label>Mail:</Form.Label>
                  <Form.Control
                    type="text"
                    name="email"
                    value={formData.email}
                    onChange={handleInputChange}
                    required
                  />
                </Form.Group>
                <Form.Group controlId="password" style={{ marginTop: 10 }}>
                  <Form.Label>Şifre:</Form.Label>
                  <Form.Control
                    type="password"
                    name="password"
                    value={formData.password}
                    onChange={handleInputChange}
                    required
                  />
                </Form.Group>
                <Button style={{ float: 'right', marginTop: 10 }} variant="primary" type="submit" block="true">
                  Giriş Yap
                </Button>
              </Form>
            </Card.Body>
          </Col>
        </Row>
      </Card>
    </div>
    </div>)
    }
}