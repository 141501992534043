import React, { useState, useContext } from 'react';
import { Form, Button, Container, Alert } from 'react-bootstrap';
import { MainContext } from '../App';
import apiService from '../api';

const ComplaintPage = () => {
  const { setProgress } = useContext(MainContext);
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [complaint, setComplaint] = useState('');
  const [file, setFile] = useState(null);
  const [message, setMessage] = useState('');
  const [error, setError] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();
    setMessage('');
    setError('');

    try {
      let fileUrl = '';
      if (file) {
        const uploadResponse = await apiService.uploadFileForComplaint(file, setProgress, handleError);
        fileUrl = uploadResponse.url;
      }

      const response = await apiService.sendComplaint(
        { name, email, complaint, fileUrl },
        setProgress,
        handleError
      );

      if (response.status === 429) {
        setError('Saatte en fazla 2 şikayet gönderebilirsiniz. Lütfen daha sonra tekrar deneyiniz.');
        return;
      }

      setMessage('Şikayetiniz başarıyla gönderildi. En kısa sürede size dönüş yapacağız.');
      setName('');
      setEmail('');
      setComplaint('');
      setFile(null);
    } catch (err) {
      handleError(err);
    }
  };

  const handleError = (err) => {
    if (err.status === 429) {
      setError('Saatte en fazla 2 şikayet gönderebilirsiniz. Lütfen daha sonra tekrar deneyiniz.');
    } else {
      setError('Şikayet gönderilirken bir hata oluştu. Lütfen daha sonra tekrar deneyin.');
    }
    console.error(err);
  };

  return (
    <Container className="mt-5">
      <h2>Şikayet Formu</h2>
      {message && <Alert variant="success">{message}</Alert>}
      {error && <Alert variant="danger">{error}</Alert>}
      <Form onSubmit={handleSubmit}>
        <Form.Group className="mb-3">
          <Form.Label>İsim</Form.Label>
          <Form.Control type="text" value={name} onChange={(e) => setName(e.target.value)} required />
        </Form.Group>
        <Form.Group className="mb-3">
          <Form.Label>E-posta</Form.Label>
          <Form.Control type="email" value={email} onChange={(e) => setEmail(e.target.value)} required />
        </Form.Group>
        <Form.Group className="mb-3">
          <Form.Label>Şikayet</Form.Label>
          <Form.Control as="textarea" rows={3} value={complaint} onChange={(e) => setComplaint(e.target.value)} required />
        </Form.Group>
        <Form.Group className="mb-3">
          <Form.Label>Dosya Ekle (İsteğe bağlı)</Form.Label>
          <Form.Control type="file" onChange={(e) => setFile(e.target.files[0])} />
        </Form.Group>
        <Button variant="primary" type="submit">
          Gönder
        </Button>
      </Form>
    </Container>
  );
};

export default ComplaintPage;
