export const GetApiBaseUrl = (env) =>
{
    switch (window.location.host)
    {
        case "localhost:3000":
        case "localhost:3002":
            return "http://localhost:3001";
        default:
            return "https://kentist-turizm-backend.azurewebsites.net"
    }
}
